import type { NextPageContext } from 'next'

const hostnamePortRegex = new RegExp('^(?<hostname>.+?)(?<port>:[0-9]+)$', 'gi')

/**
 *
 */
export const isHostWithForbiddenSubdomains = (context: NextPageContext) => {
  return (
    context.req?.headers?.host?.startsWith('localhost') ||
    context.req?.headers?.host?.endsWith('.vercel.app')
  )
}

/**
 * Function to parse hostname (sans-port) from NextPageContext request headers
 * or from query.host (for subdomain-less environments like localhost and
 * vercel preview deployments).
 */
export function parseHostname(context: NextPageContext): string {
  // Vercel sandboxes and localhost allows to specify host via query
  // since there is no way to specify subdomain.
  if (isHostWithForbiddenSubdomains(context) && context.query?.host) {
    return `${context.query.host}`
  }

  return (
    context.req?.headers?.host?.replace(hostnamePortRegex, '$<hostname>') || ''
  )
}
