// eslint-disable-next-line zeffo/no-lodash
import escape from 'lodash/escape'

import type { Api_Video } from '@core/api'
import { getVideoPoster } from '@core/helpers/video'

/**
 * Function to generate the JSON-LD for a video object
 *
 * See https://developers.google.com/search/docs/data-types/video#video-object
 * and https://schema.org/VideoObject
 *
 * Note: Fields provided by creator have to be escaped.
 */
export function videoObjectJSON(video: Api_Video) {
  const source = video.video_sources[0]
  const contentURL = source && source['src']
  const hashtags = (video.hashtags || []).map((h) => `#${h}`).join(' ')
  const description = `${video.caption} ${hashtags}\nCreated by ${video.creator.username}`
  const location = video.locale?.split('_')[1]

  return JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    [video.hashtags.length > 0 ? 'keywords' : '']: escape(
      video.hashtags.join(','),
    ),
    name: escape(video.caption),
    description: escape(description),
    thumbnailUrl: [getVideoPoster(video)],
    uploadDate: video.inserted_at,
    duration: video.duration && `T${Math.floor(video.duration)}S`,
    publisher: {
      '@type': 'Organization',
      name: 'Firework',
      logo: {
        '@type': 'ImageObject',
        url: 'https://cdn1.fireworktv.com/medias/2021/2/15/1613429354-wvuymplk/120_120/120x120.png',
        width: 120,
        height: 120,
      },
    },
    datePublished: video.published_at,
    contentUrl: contentURL,
    inLanguage: escape(video.locale),
    [location ? 'contentLocation' : '']: escape(location),
    creator: {
      '@type': 'Person',
      name: escape(video.creator.name),
      alternateName: escape(video.creator.username),
      url: `https://fw.tv/${escape(video.creator.username)}`,
      interactionStatistic: [
        {
          '@type': 'InteractionCounter',
          interactionType: { '@type': 'http://schema.org/FollowAction' },
          userInteractionCount: video.creator.followers_count,
        },
      ],
    },
    interactionStatistic: [
      {
        '@type': 'InteractionCounter',
        interactionType: { '@type': 'http://schema.org/WatchAction' },
        userInteractionCount: video.views_count,
      },
      {
        '@type': 'InteractionCounter',
        interactionService: {
          '@type': 'SoftwareApplication',
          name: 'Firework',
          url: 'https://fw.tv',
        },
        interactionType: { '@type': 'http://schema.org/ShareAction' },
        userInteractionCount: video.reposts_count + video.shares_count,
      },
    ],
  })
}
