import ipRegex from 'ip-regex'
import type { NextPageContext } from 'next/types'

import type { IMicrositePageProps, MicrositeConfig } from '@microsites/types'
import {
  createApiServicesFromSSRContext,
  getChannelNameFromSubdomain,
} from '@microsites/utils/ssr'
import { videoIdWithoutTimestamp } from '@microsites/utils/videoIdWithoutTimestamp'
import type { Api_Video } from '@src/core/api'

import { getChannelIdFromStorage } from './channelStorage'
import { parseHostname } from './parseHostname'

const NON_CHANNEL_HOSTNAMES = [
  'fw.tv',
  'localhost',
  'microsites-dev.fw.tv',
  'microsites-staging.fw.tv',
  'microsites.fw.tv',
  'www.fw.tv',
  'fw-staging.tv',
  'microsites.fw-staging.tv',
  'www.fw-staging.tv',
  'fw-dev.tv',
  'microsites.fw-dev.tv',
  'www.fw-dev.tv',
]

// 1. .well-known is a directory commonly used as a web-based protocol to fetch
//    “site-wide metadata” about a host before making a request. Looking at our
//    public folder in zeffo, we have included two files there, related to Apple
//    merchant and associated domains. Below are links about the two files:
//      apple-app-site-association
//        https://developer.apple.com/documentation/xcode/supporting-associated-domains
//      apple-developer-merchantid-domain-association
//        https://developer.apple.com/documentation/applepaywebmerchantregistrationapi/preparing_merchant_domains_for_verification
// 2. isat-nettsafe.fw.tv is a custom domain, set up in
//    ci/kustomize/production/ingress-viral.yml, that leads to a specific html
//    in the public folder (/indosat/top-viral-videos.html). This custom domain
//    does not need the channel to be fetched.
// 3. requests for service-worker.js are currently unknown
const CHANNEL_BLOCKLIST = ['.well-known', 'service-worker.js', 'isat-nettsafe']

const CHANNEL_HEADER_KEY = 'x-fw-channel-id'

function isIPAddress(hostname: string): boolean {
  return ipRegex({ exact: true }).test(hostname)
}

function parseChannelId(context: NextPageContext): string {
  const channelId = (context?.req?.headers[CHANNEL_HEADER_KEY] as string) || ''
  return channelId
}

/**
 * Helper function used to get initial props for microsite pages using NextPageContext
 */
export async function getMicrositeInitialProps(
  context: NextPageContext,
): Promise<IMicrositePageProps> {
  const hostname = parseHostname(context) || window?.location?.hostname || ''

  const isSSR = !!context.req

  // Custom domain is every domain which isnt fw.tv, or localhost for that matter. This
  // includes {channel}.fw.tv or any custom domain.
  const isCustomDomain =
    !isIPAddress(hostname) &&
    !NON_CHANNEL_HOSTNAMES.includes(hostname) &&
    !hostname.endsWith('.vercel.app')

  const channelFromHostname = getChannelNameFromSubdomain(hostname)
  const channelName =
    channelFromHostname && !CHANNEL_BLOCKLIST.includes(channelFromHostname)
      ? channelFromHostname
      : ''

  const path = context.query?.channelOrPage
    ? `/${context.query?.channelOrPage}`
    : ''

  const isPagePreview = context?.query?.is_preview === 'true'

  const videoId =
    videoIdWithoutTimestamp(
      String(context?.query?.videoId || context?.query?.fw_video || ''),
    ) || undefined

  const api = await createApiServicesFromSSRContext(context)
  let micrositeConfig: MicrositeConfig | undefined = undefined
  let ssrVideo: Api_Video | undefined = undefined

  try {
    if (isSSR) {
      const channelId = parseChannelId(context)
      if (channelId) {
        micrositeConfig = await api.getMicrositeByChannelId(channelId, {
          path,
          domain_name: hostname,
          is_preview: isPagePreview,
        })
      } else if (channelName) {
        micrositeConfig = await api.getMicrositeByChannel(channelName, {
          path,
          domain_name: hostname,
          is_preview: isPagePreview,
        })
      }
    } else {
      // client side
      if (channelName) {
        micrositeConfig = await api.getMicrositeByChannel(channelName, {
          path,
          domain_name: hostname,
          is_preview: isPagePreview,
        })
      } else {
        const channelId = getChannelIdFromStorage()
        if (channelId) {
          micrositeConfig = await api.getMicrositeByChannelId(channelId, {
            path,
            domain_name: hostname,
            is_preview: isPagePreview,
          })
        }
      }
    }
  } catch (error) {
    console.error(
      error.response?.status,
      error.response?.statusText,
      error.config?.url,
    )
  }

  try {
    if (videoId) {
      // Fetch video by video ID if applicable.
      ssrVideo = await api.getVideo(videoId)
    }
  } catch (error) {
    console.error(
      error.response.status,
      error.response.statusText,
      error.config.url,
    )
  }

  return {
    ssrChannel: micrositeConfig,
    ssrPage: micrositeConfig?.page,
    ssrVideo,
    videoId,
    isCustomDomain,
    isSSR,
    hostname,
  }
}
