import { getSessionStorage } from '@core/storage'
import { StorageKeyEssentialEnum } from '@core/storage/types'

const CHANNEL_KEY_NAME = StorageKeyEssentialEnum.fw_tv_ssrchnnl

/**
 *
 */
export const setChannelIdInStorage = (channelId: string) => {
  getSessionStorage().setItem(CHANNEL_KEY_NAME, channelId)
}

/**
 *
 */
export const getChannelIdFromStorage = (): string | null => {
  return getSessionStorage().getItem(CHANNEL_KEY_NAME)
}
