import type { GetServerSidePropsContext, NextPageContext } from 'next'

import type { ApiService } from '@core/api'
import { createApiService } from '@core/api'
import { setupAxiosClient } from '@microsites/utils/setupAxiosClient'
import { defaultApiHost } from '@src/helpers'

/**
 *
 */
export function getChannelNameFromSubdomain(host: string): string | null {
  if (/vercel\.app/.test(host)) {
    // Vercel sandbox deployments are always on a subdomain, we do not want
    // those to be interpreted as a channel
    return null
  }
  const subdomainMatches = host.match(/([^.]+)\.fw(-dev|-staging)?.tv/)
  const channel = subdomainMatches && subdomainMatches[1]

  if (channel && /www/.test(channel)) {
    // Special subdomains that we do not want to consider as channels
    return null
  }
  return channel
}

/**
 *
 */
export const createApiServicesFromSSRContext = async (
  context: GetServerSidePropsContext | NextPageContext,
): Promise<ApiService> => {
  return createApiService(defaultApiHost, context, setupAxiosClient)
}
