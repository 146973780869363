import { css } from '@emotion/react'
import { t } from 'i18next'

import type { IAPI_Channel } from '@core/api'
import { Markdown } from '@core/components/Markdown/next'
import { resizeThumbnailUrl } from '@embed/components/helpers'

interface Props {
  channel: IAPI_Channel['channel']
}

/**
 *
 */
export const MobileChannelInfo: React.FunctionComponent<Props> = ({
  channel,
}) => {
  return (
    <div
      css={css`
        margin-top: 24px;
        margin-bottom: 16px;
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
        `}
      >
        <div
          css={css`
            align-items: center;
            display: flex;
          `}
        >
          <img
            alt={t('next:Channel avatar')}
            src={resizeThumbnailUrl(channel?.avatar_url, { size: [100, 100] })}
            height="60"
            width="60"
            css={css`
              background: white;
              border-radius: 50%;
            `}
          />
          <div
            css={css`
              margin-left: 16px;
            `}
          >
            <div
              css={css`
                font-size: 12px;
                margin-bottom: 4px;
              `}
            >
              {channel?.name || channel?.username}
            </div>
          </div>
        </div>
      </div>
      {!!channel?.bio && (
        <div
          css={css`
            font-size: 12px;
            font-weight: 400;
            margin-top: 12px;
            margin-bottom: 12px;
          `}
        >
          <Markdown>{channel.bio}</Markdown>
        </div>
      )}
    </div>
  )
}
