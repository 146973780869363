import { css } from '@emotion/react'

import type { IAPI_Channel } from '@core/api'
import ChannelSidebar from '@microsites/components/Channel/ChannelSidebar'
import { Container } from '@microsites/components/Container/Container'

import { propWithBreakpoints } from '../MediaQuery'

interface IProps {
  channel: IAPI_Channel['channel']
}

/**
 *
 */
export const ChannelLayout: React.FunctionComponent<
  React.PropsWithChildren<IProps>
> = ({ channel, children }) => (
  <Container
    css={[
      css`
        display: flex;
      `,
      propWithBreakpoints('padding-bottom', ['env(safe-area-inset-bottom)', 0]),
    ]}
  >
    <ChannelSidebar channel={channel} />
    <div
      css={css`
        flex: 1;
        position: relative;
        margin-top: 32px;
      `}
    >
      {children}
    </div>
  </Container>
)
