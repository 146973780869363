import React from 'react'

import Head from 'next/head'

import { getVideoPoster } from '@core/helpers/video'
import { resizeThumbnailUrl } from '@embed/components/helpers'
import { videoObjectJSON } from '@microsites/utils/google'

/**
 *
 */
const VideoHead = ({ video }) => {
  const thumbnailUrl = resizeThumbnailUrl(getVideoPoster(video), { size: null })
  const title = video.caption || 'Firework'
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={video.caption} />
      <meta name="robots" content="max-image-preview:large" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={video.caption} />
      <meta property="og:image" content={thumbnailUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={video.locale} />
      <meta property="og:video" content={video.download_url} />
      <meta
        property="og:url"
        content={`https://fw.tv/videos/${video.encoded_id}`}
      />
      <meta property="og:video:type" content="video/mp4" />
      <meta property="og:video:duration" content={video.duration} />
      <meta property="og:video:width" content={video.width} />
      <meta property="og:video:height" content={video.height} />
      <meta property="og:site_name" content="Firework" />
      <meta property="og:image" content={thumbnailUrl} />
      <meta
        property="al:ios:url"
        content={`firework://videos/${video.encoded_id}`}
      />
      <meta
        property="al:android:url"
        content={`firework://videos/${video.encoded_id}`}
      />
      <meta
        property="al:web:url"
        content={`https://fw.tv/videos/${video.encoded_id}`}
      />
      <link rel="canonical" href={`https://fw.tv/videos/${video.encoded_id}`} />
      <meta property="twitter:card" content="player" />
      <meta property="twitter:site" content="@fireworkhq" />
      <meta
        property="twitter:url"
        content={`https://fw.tv/videos/${video.encoded_id}`}
      />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={video.caption} />
      <meta property="twitter:image" content={thumbnailUrl} />
      <meta property="twitter:player" content={`/videos/${video.encoded_id}`} />
      <meta property="twitter:player:width" content={video.width} />
      <meta property="twitter:player:height" content={video.height} />
      <meta
        property="twitter:app:url:iphone"
        content={`firework://videos/${video.encoded_id}`}
      />
      <meta
        property="twitter:app:url:googleplay"
        content={`firework://videos/${video.encoded_id}`}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: videoObjectJSON(video) }}
      />
    </Head>
  )
}

export default VideoHead
