import { useTranslation } from 'react-i18next'

import { css } from '@emotion/react'

import type { IAPI_Channel } from '@core/api'
import { Markdown } from '@core/components/Markdown/next'
import { resizeThumbnailUrl } from '@embed/components/helpers'
import {
  LargeAndAbove,
  propWithBreakpoints,
  SmallAndAbove,
} from '@microsites/components/MediaQuery'

interface IProps {
  channel: IAPI_Channel['channel']
}

/**
 *
 */
const ChannelSidebar: React.FunctionComponent<IProps> = ({ channel }) => {
  const { t } = useTranslation()

  return (
    <div>
      <SmallAndAbove>
        <div
          css={[
            css`
              display: flex;
              flex-direction: column;
              height: 100vh;
              padding-bottom: 20px;
              position: sticky;
              top: 0;
              z-index: 3;
            `,
            propWithBreakpoints('width', ['unset', '70px', '70px', '225px']),
            propWithBreakpoints('margin-right', ['0', '0', '0', '50px']),
            propWithBreakpoints('padding-top', ['0', '0', '0', '12px']),
          ]}
        >
          {channel && (
            <LargeAndAbove>
              <div
                css={css`
                  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
                  margin-bottom: 16px;
                  padding-bottom: 16px;
                  padding-top: 16px;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    margin-bottom: 16px;
                  `}
                >
                  <img
                    alt={t('next:Channel avatar')}
                    key={channel.avatar_url}
                    src={resizeThumbnailUrl(channel.avatar_url, {
                      size: [100, 100],
                    })}
                    css={css`
                      background: white;
                      border-radius: 50%;
                      flex-shrink: 0;
                      height: 60px;
                      margin-right: 16px;
                      width: 60px;
                    `}
                  />
                  <span
                    css={css`
                      font-size: 12px;
                      font-weight: 400;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: break-spaces;
                    `}
                  >
                    {channel.name || channel.username}
                  </span>
                </div>
                {!!channel.bio && (
                  <div
                    css={css`
                      font-size: 14px;
                      font-weight: 400;
                      margin-top: 16px;
                      margin-bottom: 16px;
                      word-break: break-word;
                    `}
                  >
                    <Markdown>{channel.bio}</Markdown>
                  </div>
                )}
                {channel.promote_url && (
                  <a
                    href={channel.promote_url}
                    // variant="secondary"
                    css={css`
                      -webkit-text-decoration: none;
                      background: #404040;
                      border-radius: 500px;
                      border: 0;
                      color: white;
                      cursor: pointer;
                      display: block;
                      display: block;
                      font-size: 14px;
                      font-weight: 600;
                      margin-top: 12px;
                      padding-bottom: 8px;
                      padding-left: 16px;
                      padding-right: 16px;
                      padding-top: 8px;
                      text-align: center;
                      text-decoration: none;
                      width: 100%;
                    `}
                  >
                    {t('next:Visit Site')}
                  </a>
                )}
              </div>
            </LargeAndAbove>
          )}
        </div>
      </SmallAndAbove>
    </div>
  )
}

export default ChannelSidebar
