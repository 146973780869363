import type { NextPageContext } from 'next/types'

import { isHostWithForbiddenSubdomains, parseHostname } from './parseHostname'

export enum Redirect {
  MOVED_PERMANENTLY = 301,
  MOVED_TEMPORARILY = 302,
}

const FW_DOMAINS = ['fw.tv', 'fw-staging.tv', 'fw-dev.tv']

/**
 * `getServerSideProps` function for all pages which needs to redirect
 * to a proper channel subdomain. Will perform a redirect and closes
 * the response.
 *
 * Redirects
 * 1. "fw.tv/channel" -> "channel.fw.tv"
 * 2. "fw.tv/channel/videos/123" -> "channel.fw.tv/watch?fw_video=123"
 * 3. "fw.tv/videos/123" -> "fw.tv/watch?fw_video=123"
 * 4. "localhost:port/channel" -> "/?host=channel.fw.tv"
 * 5. "localhost:port/channel/videos/123" -> "/watch?fw_video=123&host=channel.fw.tv"
 */
export function redirectToChannelSubdomain(context: NextPageContext) {
  const {
    query: { channelOrPage: channel, videoId, ...queryParams },
  } = context

  // Valid hostname for URL constructor to be replaced
  // later to achieve relative URL.
  const hostnamePlaceholder = 'http://replace-me'
  const hostname = parseHostname(context)
  const origin = FW_DOMAINS.includes(hostname)
    ? channel
      ? `https://${channel}.${hostname}`
      : `https://${hostname}`
    : hostnamePlaceholder

  const urlObj = new URL('/', origin)

  if (isHostWithForbiddenSubdomains(context)) {
    // Sets the default hostname for subdomain-less environments
    // like localhost and vercel preview deployments.
    urlObj.searchParams.set('host', channel ? `${channel}.fw.tv` : hostname)
  }

  if (videoId) {
    urlObj.pathname = '/watch'
    Object.entries(queryParams).forEach(([key, value]) =>
      urlObj.searchParams.set(key, String(value)),
    )
    urlObj.searchParams.set('fw_video', String(videoId))
  }

  context.res?.writeHead(Redirect.MOVED_PERMANENTLY, {
    Location: urlObj.toString().replace(hostnamePlaceholder, ''),
  })
  context.res?.end()
}

/**
 * Client side history.pushState with preserved
 * query string hostname.
 */
export function historyPush(url: string) {
  if (typeof window !== 'undefined') {
    const urlObj = new URL(url, window.location.href)
    const searchObj = new URLSearchParams(window.location.search)

    // Preserve `host` from search query string
    if (searchObj.has('host')) {
      urlObj.searchParams.set('host', searchObj.get('host') || '')
    }

    window.history.pushState(null, '', urlObj.toString())
  }
}
