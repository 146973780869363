import type { AxiosInstance } from 'axios'
import axios from 'axios'
import type { GetServerSidePropsContext, NextPageContext } from 'next'

/**
 *
 */
export const setupAxiosClient = (
  apiHost: string,
  context?: GetServerSidePropsContext | NextPageContext,
): AxiosInstance => {
  const headers: Record<string, string> = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }

  if (context?.req) {
    if (context.req.headers.cookie) {
      headers.cookie = context.req.headers.cookie
    }
    if (context.req.headers['x-forwarded-for']) {
      const forwardedFor = context.req.headers['x-forwarded-for']
      headers['FW-Forwarded-For'] = Array.isArray(forwardedFor)
        ? forwardedFor.join(', ')
        : forwardedFor
    }
  }
  const axiosInstance = axios.create({
    baseURL: apiHost,
    headers,
  })
  if (context?.res) {
    axiosInstance.interceptors.response.use((axiosResponse) => {
      const setCookieHeader = axiosResponse.headers['set-cookie']
      if (setCookieHeader) {
        context.res!.setHeader('set-cookie', setCookieHeader)
      }
      return axiosResponse
    })
  }

  return axiosInstance
}
