import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { css } from '@emotion/react'
import { connect } from 'redux-zero/react'

import { sendPixelTrackingWithSession } from '@core/tracking'
import { parseQueryParams } from '@core/window'
import type { IAppContext } from '@embed/components/helpers'
import { AppContextType } from '@embed/components/helpers'
import { extractPageUrlPwa } from '@embed/helpers'
import { ChannelLayout } from '@microsites/components/Channel/ChannelLayout'
import { MobileChannelInfo } from '@microsites/components/Channel/MobileChannelInfo'
import {
  MediumAndBelow,
  propWithBreakpoints,
} from '@microsites/components/MediaQuery'
import { MicrositeHead } from '@microsites/components/MicrositeHead'
import VideoHead from '@microsites/components/VideoHead/VideoHead'
import { useMicrositeUIConfig } from '@microsites/hooks/useMicrositeUIConfig'
import type { IPWAState } from '@microsites/store'
import { dispatch } from '@microsites/store'
import { FEED_VIEW_CHANNEL } from '@microsites/tracking/endpoints'
import type { IMicrositePageProps } from '@microsites/types'
import { setChannelIdInStorage } from '@microsites/utils/microsites/channelStorage'
import { historyPush } from '@microsites/utils/microsites/routing'

import { ChannelGrid } from './ChannelGrid'

interface IProps extends IMicrositePageProps {
  params: IPWAState['params']
}

const Channel: React.FunctionComponent<IProps> = ({
  ssrChannel,
  ssrVideo,
  params,
  isCustomDomain,
}) => {
  const username = ssrChannel?.username || ''
  const pageUrl = extractPageUrlPwa(params)
  const channel = ssrChannel
  const channelId = channel?.encoded_id
  const channelUsername = channel?.username
  const appContext = useMemo(() => {
    return {
      appContextType: AppContextType.CHANNEL_CONTEXT_TYPE,
      channelId,
      pageUrl,
      username: channelUsername,
      isCustomDomain,
    }
  }, [
    channelId,
    pageUrl,
    channelUsername,
    isCustomDomain,
  ]) as unknown as IAppContext

  const channelStored = useRef(false)

  useEffect(() => {
    if (ssrChannel && !channelStored.current) {
      setChannelIdInStorage(ssrChannel.encoded_id)
      channelStored.current = true
    }
  })

  useMicrositeUIConfig(ssrChannel)

  const { t } = useTranslation()

  useEffect(() => {
    dispatch.setContext({ appContext })
  }, [appContext])

  useEffect(() => {
    if (channelId) {
      const searchParams = parseQueryParams(window.location.search)
      const referrerChannelId = searchParams.referrer_channel_id
      let referrerUrl
      if (!params.referrer?.match(window.location.origin)) {
        referrerUrl = params.referrer
      }
      const data = {
        event_properties: {
          _channel_id: channelId,
          _referrer_channel_id: referrerChannelId,
          referrer_url: referrerUrl,
        },
      }
      sendPixelTrackingWithSession({
        endpoint: FEED_VIEW_CHANNEL,
        data,
        host: undefined,
      })
    }
  }, [channelId, params.referrer])

  // Callback executed with every navigation to a video -
  // will update the url with the video id.
  const navigateToVideo = useCallback((videoId: string) => {
    historyPush(`/watch?fw_video=${videoId}`)
  }, [])

  // Effect listening to the quit event to reset the url.
  useEffect(() => {
    const onClose = () => {
      historyPush('/')
    }

    document.addEventListener('fw:player:quit', onClose)
    return () => {
      document.removeEventListener('fw:player:quit', onClose)
    }
  }, [])

  // Effect listening to the player content (video) change event
  // to update the url.
  useEffect(() => {
    const handler = (event: Event) => {
      navigateToVideo(event.detail.video.encoded_id)
    }
    document.addEventListener('fw:player:content-change', handler)
    return () => {
      document.removeEventListener('fw:player:content-change', handler)
    }
  }, [navigateToVideo])

  if (!channel) {
    return null
  }

  // Fake channels testing for calls across subdomain on staging
  if (
    channel.username === 'oto_subdomain_1' ||
    channel.username === 'oto_subdomain_2'
  ) {
    const otherChannel =
      channel.username === 'oto_subdomain_1'
        ? 'oto_subdomain_2'
        : 'oto_subdomain_1'

    return (
      <div style={{ margin: '10px' }}>
        <a href={`https://${otherChannel}.fw-staging.tv`}>
          {t('next:Navigate to another subdomain page')}
        </a>
        <fw-live-helper></fw-live-helper>
        <script
          async
          src="/js/live-helper.js"
          data-chat_channel_id="N5ma5y"
        ></script>
      </div>
    )
  }

  return (
    <ChannelLayout channel={channel}>
      <div
        css={[
          css`
            position: relative;
          `,
          propWithBreakpoints('margin-left', ['12px', 'none']),
          propWithBreakpoints('margin-right', ['12px', 'none']),
        ]}
      >
        {channel && (
          <MediumAndBelow>
            <MobileChannelInfo channel={channel} />
          </MediumAndBelow>
        )}

        <ChannelGrid
          channel={channel}
          video={ssrVideo}
          onClick={navigateToVideo}
        />
      </div>
      {channel?.promote_url && (
        <MediumAndBelow>
          <a
            href={channel.promote_url}
            // variant="secondary"
            css={css`
              position: fixed;
              bottom: calc(60px + env(safe-area-inset-bottom));
              left: 50%;
              transform: translateX(-50%);
              display: flex;
              align-items: center;
              justify-content: center;
              min-width: 130;
            `}
          >
            {t('next:Visit Site')}
            <img
              alt={t('next:Open in new tab')}
              src={require('@images/right-chevron-white.svg')}
              width="40"
              height="40"
              css={css`
                margin-top: -8px;
                margin-bottom: -8px;
                margin-right: -16px;
              `}
            />
          </a>
        </MediumAndBelow>
      )}
      {ssrVideo && <VideoHead video={ssrVideo} />}
      {!ssrVideo && (channel || ssrChannel) && (
        <MicrositeHead
          username={username}
          metadata={{ meta_description: channel.bio }}
          image={channel.avatar_url}
          font={(channel || ssrChannel)?.config?.ui_font}
        />
      )}
    </ChannelLayout>
  )
}

export default connect<IPWAState, IMicrositePageProps>((state) => {
  return {
    params: state.params,
  }
})(Channel)
