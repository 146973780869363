import { useEffect } from 'react'

import type { Api_Video, IAPI_Channel } from '@core/api'

interface IProps {
  channel: IAPI_Channel['channel']
  video?: Api_Video
  onClick: (videoId: string) => void
}

/**
 * Render channel grid by using <fw-embed-feed>. Subscribe to the click events
 * so that we can navigate to the video page.
 * @param props
 * @returns
 */
export const ChannelGrid: React.FunctionComponent<IProps> = (props) => {
  const { channel, video, onClick } = props

  useEffect(() => {
    const handler = (event: Event) => {
      onClick(event.detail.video.encoded_id)
    }
    document.addEventListener('fw:embed-feed:thumbnail-click', handler)
    return () => {
      document.removeEventListener('fw:embed-feed:thumbnail-click', handler)
    }
  }, [onClick])

  return (
    <fw-embed-feed
      channel={channel.username}
      video={video?.encoded_id || ''}
      mode="grid"
      pip="false"
    />
  )
}
